import lightGallery from "lightgallery"
import lgPager from "lightgallery/plugins/pager"
import lgAutoplay from "lightgallery/plugins/autoplay"

export function initGallery() {
  /* gallery */
  const galleries = Array.from(
    document.getElementsByClassName("gallery__slides"),
  )

  if (galleries) {
    galleries.forEach((gallery) => {
      const inlineGallery = lightGallery(gallery, {
        container: gallery,
        selector: ".gallery__slide",
        plugins: [lgPager, lgAutoplay],
        pager: gallery.children.length > 1,
        autoplay: true,
        slideShowAutoplay: true,
        progressBar: false,
        autoplayControls: false,
        slideShowInterval: 8000,
        closable: false,
        showMaximizeIcon: false,
        download: false,
        counter: false,
        allowMediaOverlap: true,
        getCaptionFromTitleOrAlt: false,
        appendSubHtmlTo: ".lg-outer",
        mobileSettings: {
          controls: false,
          showCloseIcon: false,
          download: false,
        },
        licenseKey: "5BB3F8AD-3458437D-A43F0667-D9E45AEA",
      })

      inlineGallery.openGallery()
    })
  }
}
