import { qs, qsa } from "../utils/dom"

export function initProfileImageGenerator() {
  for (let wrap of qsa(".profgen")) {
    let settings = JSON.parse(qs("script", wrap).textContent)

    let canvas = qs("canvas", wrap)
    let ctx = canvas.getContext("2d")

    let overlay = new Image()
    overlay.src = settings.overlay

    let download = qs(".profgen__download", wrap)

    qs("input", wrap).addEventListener("change", (e) => {
      let files = e.target.files
      if (files.length) {
        let url = URL.createObjectURL(files[0])
        let image = new Image()
        image.addEventListener("load", () => {
          ctx.clearRect(0, 0, canvas.width, canvas.height)

          let w = image.width,
            h = image.height
          let sideLength = Math.min(w, h)
          let x = Math.floor(w / 2 - sideLength / 2)
          let y = Math.floor(h / 2 - sideLength / 2)

          ctx.drawImage(
            image,
            x,
            y,
            sideLength,
            sideLength,
            0,
            0,
            canvas.width,
            canvas.width,
          )
          URL.revokeObjectURL(url)
          ctx.globalCompositeOperation = "soft-light"
          ctx.fillStyle = settings.tint
          ctx.fillRect(0, 0, canvas.width, canvas.width)
          ctx.globalCompositeOperation = "source-over"
          ctx.drawImage(
            overlay,
            0,
            0,
            overlay.width,
            overlay.height,
            0,
            0,
            canvas.width,
            canvas.width,
          )

          canvas.classList.remove("hide")
          download.classList.remove("hide")
        })
        image.src = url
      }
    })

    download.addEventListener("click", (e) => {
      e.preventDefault()

      canvas.toBlob((blob) => {
        let a = document.createElement("a")
        a.download = "profile.png"
        a.href = URL.createObjectURL(blob)
        a.click()
      }, "image/png")
    })
  }
}
