export const NAMES = [
  ["1", 8914, "ZH"],
  ["2", 8909, "ZH"],
  ["3", 8906, "ZH"],
  ["4", 8925, "ZH"],
  ["5", 8908, "ZH"],
  ["6", 8926, "ZH"],
  ["7", 8934, "ZH"],
  ["8", 8933, "ZH"],
  ["9", 8932, "ZH"],
  ["10", 8912, "ZH"],
  ["11", 8913, "ZH"],
  ["12", 8911, "ZH"],
  ["13", 8143, "ZH"],
  ["14", 8907, "ZH"],
  ["21", 8452, "ZH"],
  ["22", 8463, "ZH"],
  ["23", 8415, "ZH"],
  ["24", 8414, "ZH"],
  ["25", 8447, "ZH"],
  ["26", 8458, "ZH"],
  ["27", 8245, "ZH"],
  ["28", 8416, "ZH"],
  ["29", 8247, "ZH"],
  ["30", 8450, "ZH"],
  ["31", 8444, "ZH"],
  ["32", 8457, "ZH"],
  ["33", 8453, "ZH"],
  ["34", 8248, "ZH"],
  ["35", 8460, "ZH"],
  ["37", 8475, "ZH"],
  ["38", 8462, "ZH"],
  ["39", 8478, "ZH"],
  ["40", 8466, "ZH"],
  ["41", 8467, "ZH"],
  ["43", 8459, "ZH"],
  ["51", 8184, "ZH"],
  ["52", 8303, "ZH"],
  ["53", 8180, "ZH"],
  ["54", 8305, "ZH"],
  ["55", 8193, "ZH"],
  ["56", 8424, "ZH"],
  ["57", 8427, "ZH"],
  ["58", 8192, "ZH"],
  ["59", 8182, "ZH"],
  ["60", 8181, "ZH"],
  ["61", 8194, "ZH"],
  ["62", 8000, "ZH"],
  ["63", 8426, "ZH"],
  ["64", 8309, "ZH"],
  ["65", 8425, "ZH"],
  ["66", 8152, "ZH"],
  ["67", 8197, "ZH"],
  ["68", 8427, "ZH"],
  ["69", 8301, "ZH"],
  ["70", 8195, "ZH"],
  ["71", 8196, "ZH"],
  ["72", 8185, "ZH"],
  ["81", 8164, "ZH"],
  ["82", 8113, "ZH"],
  ["83", 8107, "ZH"],
  ["84", 8108, "ZH"],
  ["85", 8114, "ZH"],
  ["86", 8157, "ZH"],
  ["87", 8115, "ZH"],
  ["88", 8173, "ZH"],
  ["89", 8172, "ZH"],
  ["90", 8155, "ZH"],
  ["91", 8166, "ZH"],
  ["92", 8154, "ZH"],
  ["93", 8165, "ZH"],
  ["94", 8112, "ZH"],
  ["95", 8158, "ZH"],
  ["96", 8105, "ZH"],
  ["97", 8153, "ZH"],
  ["98", 8165, "ZH"],
  ["99", 8165, "ZH"],
  ["100", 8174, "ZH"],
  ["101", 8162, "ZH"],
  ["102", 8187, "ZH"],
  ["111", 8345, "ZH"],
  ["112", 8608, "ZH"],
  ["113", 8635, "ZH"],
  ["114", 8498, "ZH"],
  ["115", 8614, "ZH"],
  ["116", 8627, "ZH"],
  ["117", 8340, "ZH"],
  ["118", 8630, "ZH"],
  ["119", 8607, "ZH"],
  ["120", 8639, "ZH"],
  ["121", 8620, "ZH"],
  ["131", 8134, "ZH"],
  ["135", 8802, "ZH"],
  ["136", 8135, "ZH"],
  ["137", 8942, "ZH"],
  ["138", 8805, "ZH"],
  ["139", 8803, "ZH"],
  ["141", 8800, "ZH"],
  ["151", 8703, "ZH"],
  ["152", 8704, "ZH"],
  ["153", 8634, "ZH"],
  ["154", 8700, "ZH"],
  ["155", 8708, "ZH"],
  ["156", 8706, "ZH"],
  ["157", 8618, "ZH"],
  ["158", 8712, "ZH"],
  ["159", 8707, "ZH"],
  ["160", 8126, "ZH"],
  ["161", 8125, "ZH"],
  ["172", 8320, "ZH"],
  ["173", 8335, "ZH"],
  ["176", 8310, "ZH"],
  ["177", 8331, "ZH"],
  ["178", 8332, "ZH"],
  ["180", 8484, "ZH"],
  ["181", 8492, "ZH"],
  ["182", 8489, "ZH"],
  ["191", 8600, "ZH"],
  ["192", 8132, "ZH"],
  ["193", 8118, "ZH"],
  ["194", 8606, "ZH"],
  ["195", 8124, "ZH"],
  ["196", 8617, "ZH"],
  ["197", 8603, "ZH"],
  ["198", 8610, "ZH"],
  ["199", 8604, "ZH"],
  ["200", 8602, "ZH"],
  ["211", 8479, "ZH"],
  ["213", 8311, "ZH"],
  ["214", 8471, "ZH"],
  ["215", 8421, "ZH"],
  ["216", 8474, "ZH"],
  ["218", 8548, "ZH"],
  ["219", 8352, "ZH"],
  ["220", 8523, "ZH"],
  ["221", 8442, "ZH"],
  ["223", 8413, "ZH"],
  ["224", 8422, "ZH"],
  ["225", 8545, "ZH"],
  ["226", 8418, "ZH"],
  ["227", 8472, "ZH"],
  ["228", 8495, "ZH"],
  ["230", 8400, "ZH"],
  ["231", 8483, "ZH"],
  ["241", 8904, "ZH"],
  ["242", 8903, "ZH"],
  ["243", 8953, "ZH"],
  ["244", 8954, "ZH"],
  ["245", 8102, "ZH"],
  ["246", 8955, "ZH"],
  ["247", 8952, "ZH"],
  ["248", 8142, "ZH"],
  ["249", 8103, "ZH"],
  ["250", 8902, "ZH"],
  ["251", 8951, "ZH"],
  ["261", 8000, "ZH"],
  ["292", 8476, "ZH"],
  ["293", 8820, "ZH"],
  ["294", 8353, "ZH"],
  ["295", 8816, "ZH"],
  ["296", 8307, "ZH"],
  ["297", 8493, "ZH"],
  ["298", 8542, "ZH"],
  ["301", 3270, "BE"],
  ["302", 3282, "BE"],
  ["303", 3262, "BE"],
  ["304", 3283, "BE"],
  ["305", 3273, "BE"],
  ["306", 3250, "BE"],
  ["307", 3046, "BE"],
  ["309", 3271, "BE"],
  ["310", 3256, "BE"],
  ["311", 3054, "BE"],
  ["312", 3035, "BE"],
  ["321", 4912, "BE"],
  ["322", 4944, "BE"],
  ["323", 4913, "BE"],
  ["324", 3368, "BE"],
  ["325", 4917, "BE"],
  ["326", 4955, "BE"],
  ["329", 4900, "BE"],
  ["331", 4932, "BE"],
  ["332", 4936, "BE"],
  ["333", 4917, "BE"],
  ["335", 4943, "BE"],
  ["336", 4919, "BE"],
  ["337", 4914, "BE"],
  ["338", 4938, "BE"],
  ["339", 4938, "BE"],
  ["340", 4933, "BE"],
  ["341", 4911, "BE"],
  ["342", 4922, "BE"],
  ["344", 4937, "BE"],
  ["345", 4923, "BE"],
  ["351", 3000, "BE"],
  ["352", 3065, "BE"],
  ["353", 3047, "BE"],
  ["354", 3038, "BE"],
  ["355", 3098, "BE"],
  ["356", 3073, "BE"],
  ["357", 3096, "BE"],
  ["358", 3066, "BE"],
  ["359", 3068, "BE"],
  ["360", 3033, "BE"],
  ["361", 3052, "BE"],
  ["362", 3063, "BE"],
  ["363", 3072, "BE"],
  ["371", 2500, "BE"],
  ["372", 2532, "BE"],
  ["381", 3296, "BE"],
  ["382", 3263, "BE"],
  ["383", 3294, "BE"],
  ["385", 3264, "BE"],
  ["386", 3293, "BE"],
  ["387", 2543, "BE"],
  ["388", 3297, "BE"],
  ["389", 3294, "BE"],
  ["390", 2554, "BE"],
  ["391", 3298, "BE"],
  ["392", 2542, "BE"],
  ["393", 3295, "BE"],
  ["394", 3251, "BE"],
  ["401", 3426, "BE"],
  ["402", 3473, "BE"],
  ["403", 3323, "BE"],
  ["404", 3400, "BE"],
  ["405", 3423, "BE"],
  ["406", 3419, "BE"],
  ["407", 3412, "BE"],
  ["408", 3429, "BE"],
  ["409", 3324, "BE"],
  ["410", 3429, "BE"],
  ["411", 3309, "BE"],
  ["412", 3422, "BE"],
  ["413", 3425, "BE"],
  ["414", 3325, "BE"],
  ["415", 3421, "BE"],
  ["418", 3414, "BE"],
  ["420", 3422, "BE"],
  ["421", 3472, "BE"],
  ["422", 3421, "BE"],
  ["423", 3425, "BE"],
  ["424", 3474, "BE"],
  ["431", 2606, "BE"],
  ["432", 2610, "BE"],
  ["433", 2607, "BE"],
  ["434", 2608, "BE"],
  ["435", 2333, "BE"],
  ["437", 2723, "BE"],
  ["438", 2534, "BE"],
  ["441", 2616, "BE"],
  ["442", 2538, "BE"],
  ["443", 2610, "BE"],
  ["444", 2605, "BE"],
  ["445", 2615, "BE"],
  ["446", 2722, "BE"],
  ["448", 2613, "BE"],
  ["449", 2537, "BE"],
  ["450", 2603, "BE"],
  ["491", 3237, "BE"],
  ["492", 3235, "BE"],
  ["493", 2577, "BE"],
  ["494", 3238, "BE"],
  ["495", 3236, "BE"],
  ["496", 3232, "BE"],
  ["497", 2576, "BE"],
  ["498", 3225, "BE"],
  ["499", 2577, "BE"],
  ["500", 3226, "BE"],
  ["501", 3233, "BE"],
  ["502", 3234, "BE"],
  ["533", 3315, "BE"],
  ["535", 3053, "BE"],
  ["536", 3053, "BE"],
  ["538", 3312, "BE"],
  ["540", 3303, "BE"],
  ["541", 3305, "BE"],
  ["543", 3322, "BE"],
  ["544", 3302, "BE"],
  ["546", 3053, "BE"],
  ["551", 3322, "BE"],
  ["552", 3427, "BE"],
  ["553", 3053, "BE"],
  ["554", 3428, "BE"],
  ["556", 4564, "BE"],
  ["557", 3303, "BE"],
  ["561", 3715, "BE"],
  ["562", 3711, "BE"],
  ["563", 3714, "BE"],
  ["564", 3717, "BE"],
  ["565", 3718, "BE"],
  ["566", 3704, "BE"],
  ["567", 3714, "BE"],
  ["571", 3800, "BE"],
  ["572", 3806, "BE"],
  ["573", 3855, "BE"],
  ["574", 3856, "BE"],
  ["575", 3707, "BE"],
  ["576", 3818, "BE"],
  ["577", 3814, "BE"],
  ["578", 3815, "BE"],
  ["579", 3804, "BE"],
  ["580", 3858, "BE"],
  ["581", 3800, "BE"],
  ["582", 3807, "BE"],
  ["584", 3822, "BE"],
  ["585", 3706, "BE"],
  ["586", 3816, "BE"],
  ["587", 3800, "BE"],
  ["588", 3853, "BE"],
  ["589", 3854, "BE"],
  ["590", 3852, "BE"],
  ["591", 3813, "BE"],
  ["592", 3855, "BE"],
  ["593", 3800, "BE"],
  ["594", 3812, "BE"],
  ["602", 3508, "BE"],
  ["603", 3507, "BE"],
  ["605", 3533, "BE"],
  ["606", 3671, "BE"],
  ["607", 3510, "BE"],
  ["608", 3506, "BE"],
  ["609", 3510, "BE"],
  ["610", 3671, "BE"],
  ["611", 3629, "BE"],
  ["612", 3503, "BE"],
  ["613", 3434, "BE"],
  ["614", 3673, "BE"],
  ["615", 3532, "BE"],
  ["616", 3110, "BE"],
  ["617", 3504, "BE"],
  ["619", 3672, "BE"],
  ["620", 3531, "BE"],
  ["622", 3629, "BE"],
  ["623", 3113, "BE"],
  ["626", 3512, "BE"],
  ["627", 3077, "BE"],
  ["628", 3532, "BE"],
  ["629", 3504, "BE"],
  ["630", 3112, "BE"],
  ["632", 3114, "BE"],
  ["662", 3206, "BE"],
  ["663", 3202, "BE"],
  ["665", 3208, "BE"],
  ["666", 3179, "BE"],
  ["667", 3177, "BE"],
  ["668", 3203, "BE"],
  ["669", 1797, "BE"],
  ["670", 3176, "BE"],
  ["671", 3207, "BE"],
  ["681", 2744, "BE"],
  ["683", 2735, "BE"],
  ["687", 2747, "BE"],
  ["690", 2738, "BE"],
  ["691", 2746, "BE"],
  ["692", 2743, "BE"],
  ["694", 2745, "BE"],
  ["696", 2732, "BE"],
  ["700", 2740, "BE"],
  ["701", 2742, "BE"],
  ["703", 2732, "BE"],
  ["704", 2762, "BE"],
  ["706", 2732, "BE"],
  ["707", 2732, "BE"],
  ["708", 2827, "BE"],
  ["709", 2747, "BE"],
  ["711", 2736, "BE"],
  ["713", 2720, "BE"],
  ["715", 2717, "BE"],
  ["716", 2715, "BE"],
  ["717", 2733, "BE"],
  ["723", 2520, "BE"],
  ["724", 2518, "BE"],
  ["726", 2516, "BE"],
  ["731", 2558, "BE"],
  ["732", 2564, "BE"],
  ["733", 2555, "BE"],
  ["734", 3274, "BE"],
  ["735", 3272, "BE"],
  ["736", 2575, "BE"],
  ["737", 3274, "BE"],
  ["738", 2565, "BE"],
  ["739", 2563, "BE"],
  ["740", 2514, "BE"],
  ["741", 3274, "BE"],
  ["742", 2572, "BE"],
  ["743", 2560, "BE"],
  ["744", 2552, "BE"],
  ["745", 2562, "BE"],
  ["746", 2553, "BE"],
  ["747", 2556, "BE"],
  ["748", 2556, "BE"],
  ["749", 2557, "BE"],
  ["750", 2572, "BE"],
  ["751", 2575, "BE"],
  ["754", 3272, "BE"],
  ["755", 3252, "BE"],
  ["756", 2512, "BE"],
  ["761", 3763, "BE"],
  ["762", 3753, "BE"],
  ["763", 3758, "BE"],
  ["766", 3765, "BE"],
  ["767", 3647, "BE"],
  ["768", 3646, "BE"],
  ["769", 3752, "BE"],
  ["770", 3632, "BE"],
  ["782", 3864, "BE"],
  ["783", 6085, "BE"],
  ["784", 3862, "BE"],
  ["785", 3860, "BE"],
  ["786", 3860, "BE"],
  ["791", 3766, "BE"],
  ["792", 3775, "BE"],
  ["793", 3773, "BE"],
  ["794", 3770, "BE"],
  ["841", 3784, "BE"],
  ["842", 3782, "BE"],
  ["843", 1657, "BE"],
  ["852", 3156, "BE"],
  ["853", 3153, "BE"],
  ["855", 3148, "BE"],
  ["861", 3123, "BE"],
  ["863", 3664, "BE"],
  ["866", 3115, "BE"],
  ["867", 3663, "BE"],
  ["868", 3629, "BE"],
  ["869", 3126, "BE"],
  ["870", 3122, "BE"],
  ["872", 3116, "BE"],
  ["877", 3087, "BE"],
  ["879", 3132, "BE"],
  ["880", 3088, "BE"],
  ["883", 3662, "BE"],
  ["884", 3125, "BE"],
  ["885", 3628, "BE"],
  ["886", 3665, "BE"],
  ["888", 3086, "BE"],
  ["889", 3128, "BE"],
  ["901", 3536, "BE"],
  ["902", 3553, "BE"],
  ["903", 3438, "BE"],
  ["904", 3538, "BE"],
  ["905", 3433, "BE"],
  ["906", 6197, "BE"],
  ["907", 3534, "BE"],
  ["908", 3556, "BE"],
  ["909", 3555, "BE"],
  ["921", 3633, "BE"],
  ["922", 3638, "BE"],
  ["923", 3615, "BE"],
  ["924", 3619, "BE"],
  ["925", 3617, "BE"],
  ["927", 3625, "BE"],
  ["928", 3627, "BE"],
  ["929", 3626, "BE"],
  ["931", 3622, "BE"],
  ["932", 3623, "BE"],
  ["934", 3653, "BE"],
  ["935", 3616, "BE"],
  ["936", 3638, "BE"],
  ["938", 3654, "BE"],
  ["939", 3612, "BE"],
  ["940", 3623, "BE"],
  ["941", 3634, "BE"],
  ["942", 3600, "BE"],
  ["943", 3635, "BE"],
  ["944", 3661, "BE"],
  ["945", 3614, "BE"],
  ["946", 3618, "BE"],
  ["947", 3645, "BE"],
  ["948", 3636, "BE"],
  ["951", 3416, "BE"],
  ["952", 3465, "BE"],
  ["953", 4952, "BE"],
  ["954", 4950, "BE"],
  ["955", 3452, "BE"],
  ["956", 3417, "BE"],
  ["957", 3454, "BE"],
  ["958", 3456, "BE"],
  ["959", 3464, "BE"],
  ["960", 4954, "BE"],
  ["971", 4536, "BE"],
  ["972", 3376, "BE"],
  ["973", 3366, "BE"],
  ["975", 4539, "BE"],
  ["976", 3376, "BE"],
  ["977", 3372, "BE"],
  ["979", 3360, "BE"],
  ["980", 3375, "BE"],
  ["981", 4704, "BE"],
  ["982", 3362, "BE"],
  ["983", 4538, "BE"],
  ["985", 3367, "BE"],
  ["987", 4539, "BE"],
  ["988", 3475, "BE"],
  ["989", 3367, "BE"],
  ["990", 3380, "BE"],
  ["991", 3377, "BE"],
  ["992", 3380, "BE"],
  ["993", 3374, "BE"],
  ["995", 4537, "BE"],
  ["1001", 6112, "LU"],
  ["1002", 6160, "LU"],
  ["1004", 6174, "LU"],
  ["1005", 6166, "LU"],
  ["1007", 6167, "LU"],
  ["1008", 6170, "LU"],
  ["1009", 6105, "LU"],
  ["1010", 6182, "LU"],
  ["1021", 6287, "LU"],
  ["1023", 6275, "LU"],
  ["1024", 6020, "LU"],
  ["1025", 6294, "LU"],
  ["1026", 6274, "LU"],
  ["1030", 6284, "LU"],
  ["1031", 6281, "LU"],
  ["1032", 6277, "LU"],
  ["1033", 6034, "LU"],
  ["1037", 6026, "LU"],
  ["1039", 6028, "LU"],
  ["1040", 6023, "LU"],
  ["1041", 6288, "LU"],
  ["1051", 6043, "LU"],
  ["1052", 6035, "LU"],
  ["1053", 6036, "LU"],
  ["1054", 6030, "LU"],
  ["1055", 6038, "LU"],
  ["1056", 6404, "LU"],
  ["1057", 6038, "LU"],
  ["1058", 6048, "LU"],
  ["1059", 6012, "LU"],
  ["1061", 6000, "LU"],
  ["1062", 6102, "LU"],
  ["1063", 6045, "LU"],
  ["1064", 6344, "LU"],
  ["1065", 6037, "LU"],
  ["1066", 6013, "LU"],
  ["1067", 6044, "LU"],
  ["1068", 6354, "LU"],
  ["1069", 6353, "LU"],
  ["1081", 6025, "LU"],
  ["1082", 6233, "LU"],
  ["1083", 6018, "LU"],
  ["1084", 6205, "LU"],
  ["1085", 6232, "LU"],
  ["1086", 6022, "LU"],
  ["1088", 6024, "LU"],
  ["1089", 6213, "LU"],
  ["1091", 6212, "LU"],
  ["1093", 6016, "LU"],
  ["1094", 6207, "LU"],
  ["1095", 6208, "LU"],
  ["1097", 6221, "LU"],
  ["1098", 6017, "LU"],
  ["1099", 6214, "LU"],
  ["1100", 6231, "LU"],
  ["1102", 6204, "LU"],
  ["1103", 6210, "LU"],
  ["1104", 6234, "LU"],
  ["1107", 6110, "LU"],
  ["1121", 6248, "LU"],
  ["1122", 6147, "LU"],
  ["1123", 6246, "LU"],
  ["1125", 6211, "LU"],
  ["1127", 6243, "LU"],
  ["1128", 6217, "LU"],
  ["1129", 6145, "LU"],
  ["1131", 6146, "LU"],
  ["1132", 6133, "LU"],
  ["1135", 6154, "LU"],
  ["1136", 6122, "LU"],
  ["1137", 6244, "LU"],
  ["1139", 6264, "LU"],
  ["1140", 6260, "LU"],
  ["1142", 6265, "LU"],
  ["1143", 6247, "LU"],
  ["1145", 6153, "LU"],
  ["1146", 6242, "LU"],
  ["1147", 6260, "LU"],
  ["1150", 6144, "LU"],
  ["1151", 6126, "LU"],
  ["1201", 6460, "UR"],
  ["1202", 6490, "UR"],
  ["1203", 6468, "UR"],
  ["1205", 6463, "UR"],
  ["1206", 6472, "UR"],
  ["1207", 6454, "UR"],
  ["1208", 6487, "UR"],
  ["1209", 6476, "UR"],
  ["1210", 6493, "UR"],
  ["1211", 6461, "UR"],
  ["1212", 6491, "UR"],
  ["1213", 6467, "UR"],
  ["1214", 6462, "UR"],
  ["1215", 6377, "UR"],
  ["1216", 6473, "UR"],
  ["1217", 6452, "UR"],
  ["1218", 8751, "UR"],
  ["1219", 6465, "UR"],
  ["1220", 6484, "UR"],
  ["1301", 8846, "SZ"],
  ["1311", 6410, "SZ"],
  ["1321", 8835, "SZ"],
  ["1322", 8808, "SZ"],
  ["1323", 8832, "SZ"],
  ["1331", 6405, "SZ"],
  ["1341", 8852, "SZ"],
  ["1342", 8854, "SZ"],
  ["1343", 8858, "SZ"],
  ["1344", 8853, "SZ"],
  ["1345", 8864, "SZ"],
  ["1346", 8854, "SZ"],
  ["1347", 8856, "SZ"],
  ["1348", 8857, "SZ"],
  ["1349", 8855, "SZ"],
  ["1361", 8849, "SZ"],
  ["1362", 6410, "SZ"],
  ["1363", 6434, "SZ"],
  ["1364", 6440, "SZ"],
  ["1365", 6424, "SZ"],
  ["1366", 6433, "SZ"],
  ["1367", 6436, "SZ"],
  ["1368", 8843, "SZ"],
  ["1369", 6452, "SZ"],
  ["1370", 6418, "SZ"],
  ["1371", 6417, "SZ"],
  ["1372", 6423, "SZ"],
  ["1373", 6422, "SZ"],
  ["1374", 6416, "SZ"],
  ["1375", 8842, "SZ"],
  ["1401", 6055, "OW"],
  ["1402", 6390, "OW"],
  ["1403", 6074, "OW"],
  ["1404", 6064, "OW"],
  ["1405", 6078, "OW"],
  ["1406", 6072, "OW"],
  ["1407", 6060, "OW"],
  ["1501", 6375, "NW"],
  ["1502", 6374, "NW"],
  ["1503", 6383, "NW"],
  ["1504", 6376, "NW"],
  ["1505", 6373, "NW"],
  ["1506", 6372, "NW"],
  ["1507", 6052, "NW"],
  ["1508", 6370, "NW"],
  ["1509", 6370, "NW"],
  ["1510", 6362, "NW"],
  ["1511", 6387, "NW"],
  ["1630", 8752, "GL"],
  ["1631", 8762, "GL"],
  ["1632", 8750, "GL"],
  ["1701", 6319, "ZG"],
  ["1702", 6330, "ZG"],
  ["1703", 6333, "ZG"],
  ["1704", 6313, "ZG"],
  ["1705", 6345, "ZG"],
  ["1706", 6315, "ZG"],
  ["1707", 6343, "ZG"],
  ["1708", 6312, "ZG"],
  ["1709", 6314, "ZG"],
  ["1710", 6318, "ZG"],
  ["1711", 6300, "ZG"],
  ["2008", 1473, "FR"],
  ["2011", 1482, "FR"],
  ["2016", 1532, "FR"],
  ["2022", 1544, "FR"],
  ["2025", 1470, "FR"],
  ["2027", 1533, "FR"],
  ["2029", 1776, "FR"],
  ["2035", 1485, "FR"],
  ["2038", 1410, "FR"],
  ["2041", 1566, "FR"],
  ["2043", 1541, "FR"],
  ["2044", 1528, "FR"],
  ["2045", 1565, "FR"],
  ["2050", 1483, "FR"],
  ["2051", 1567, "FR"],
  ["2053", 1773, "FR"],
  ["2054", 1541, "FR"],
  ["2055", 1468, "FR"],
  ["2061", 1673, "FR"],
  ["2063", 1681, "FR"],
  ["2066", 1608, "FR"],
  ["2067", 1689, "FR"],
  ["2068", 1553, "FR"],
  ["2072", 1673, "FR"],
  ["2079", 1686, "FR"],
  ["2086", 1692, "FR"],
  ["2087", 1680, "FR"],
  ["2089", 1674, "FR"],
  ["2096", 1680, "FR"],
  ["2097", 1673, "FR"],
  ["2099", 1678, "FR"],
  ["2102", 1670, "FR"],
  ["2113", 1687, "FR"],
  ["2114", 1694, "FR"],
  ["2115", 1749, "FR"],
  ["2117", 1691, "FR"],
  ["2121", 1669, "FR"],
  ["2122", 1644, "FR"],
  ["2123", 1652, "FR"],
  ["2124", 1636, "FR"],
  ["2125", 1630, "FR"],
  ["2128", 1653, "FR"],
  ["2129", 1647, "FR"],
  ["2130", 1653, "FR"],
  ["2131", 1646, "FR"],
  ["2134", 1666, "FR"],
  ["2135", 1663, "FR"],
  ["2137", 1648, "FR"],
  ["2138", 1656, "FR"],
  ["2140", 1633, "FR"],
  ["2143", 1638, "FR"],
  ["2145", 1661, "FR"],
  ["2147", 1649, "FR"],
  ["2148", 1632, "FR"],
  ["2149", 1634, "FR"],
  ["2152", 1625, "FR"],
  ["2153", 1642, "FR"],
  ["2155", 1627, "FR"],
  ["2160", 1628, "FR"],
  ["2162", 1665, "FR"],
  ["2163", 1654, "FR"],
  ["2173", 1742, "FR"],
  ["2174", 1754, "FR"],
  ["2175", 1782, "FR"],
  ["2177", 1744, "FR"],
  ["2183", 1720, "FR"],
  ["2186", 1741, "FR"],
  ["2194", 1724, "FR"],
  ["2196", 1700, "FR"],
  ["2197", 1762, "FR"],
  ["2198", 1763, "FR"],
  ["2200", 1772, "FR"],
  ["2206", 1723, "FR"],
  ["2208", 1753, "FR"],
  ["2211", 1740, "FR"],
  ["2216", 1723, "FR"],
  ["2217", 1772, "FR"],
  ["2220", 1724, "FR"],
  ["2226", 1733, "FR"],
  ["2228", 1752, "FR"],
  ["2230", 1723, "FR"],
  ["2233", 1725, "FR"],
  ["2234", 1756, "FR"],
  ["2235", 1782, "FR"],
  ["2236", 1696, "FR"],
  ["2237", 1746, "FR"],
  ["2238", 1732, "FR"],
  ["2250", 1796, "FR"],
  ["2254", 1791, "FR"],
  ["2257", 1785, "FR"],
  ["2258", 3284, "FR"],
  ["2261", 3280, "FR"],
  ["2262", 3212, "FR"],
  ["2265", 3210, "FR"],
  ["2266", 3213, "FR"],
  ["2271", 3280, "FR"],
  ["2272", 1721, "FR"],
  ["2274", 3286, "FR"],
  ["2275", 1793, "FR"],
  ["2276", 3216, "FR"],
  ["2278", 3214, "FR"],
  ["2284", 1788, "FR"],
  ["2292", 1719, "FR"],
  ["2293", 3186, "FR"],
  ["2294", 1735, "FR"],
  ["2295", 3178, "FR"],
  ["2296", 1714, "FR"],
  ["2299", 1716, "FR"],
  ["2300", 1737, "FR"],
  ["2301", 1718, "FR"],
  ["2303", 1736, "FR"],
  ["2304", 1717, "FR"],
  ["2305", 3185, "FR"],
  ["2306", 1712, "FR"],
  ["2307", 1734, "FR"],
  ["2308", 3182, "FR"],
  ["2309", 3175, "FR"],
  ["2321", 1617, "FR"],
  ["2323", 1615, "FR"],
  ["2325", 1619, "FR"],
  ["2328", 1614, "FR"],
  ["2333", 1617, "FR"],
  ["2335", 1609, "FR"],
  ["2336", 1623, "FR"],
  ["2337", 1699, "FR"],
  ["2338", 1624, "FR"],
  ["2401", 4622, "SO"],
  ["2402", 4640, "SO"],
  ["2403", 4703, "SO"],
  ["2404", 4623, "SO"],
  ["2405", 4626, "SO"],
  ["2406", 4625, "SO"],
  ["2407", 4702, "SO"],
  ["2408", 4628, "SO"],
  ["2421", 4714, "SO"],
  ["2422", 4710, "SO"],
  ["2424", 4715, "SO"],
  ["2425", 4718, "SO"],
  ["2426", 4712, "SO"],
  ["2427", 4713, "SO"],
  ["2428", 4717, "SO"],
  ["2430", 4716, "SO"],
  ["2445", 4585, "SO"],
  ["2455", 4571, "SO"],
  ["2456", 4584, "SO"],
  ["2457", 3254, "SO"],
  ["2461", 3253, "SO"],
  ["2463", 4588, "SO"],
  ["2464", 4574, "SO"],
  ["2465", 4586, "SO"],
  ["2471", 4112, "SO"],
  ["2472", 4413, "SO"],
  ["2473", 4143, "SO"],
  ["2474", 4145, "SO"],
  ["2475", 4146, "SO"],
  ["2476", 4112, "SO"],
  ["2477", 4116, "SO"],
  ["2478", 4412, "SO"],
  ["2479", 4118, "SO"],
  ["2480", 4206, "SO"],
  ["2481", 4108, "SO"],
  ["2491", 4633, "SO"],
  ["2492", 4468, "SO"],
  ["2493", 4654, "SO"],
  ["2495", 5013, "SO"],
  ["2497", 4653, "SO"],
  ["2499", 4655, "SO"],
  ["2500", 4632, "SO"],
  ["2501", 4652, "SO"],
  ["2502", 4634, "SO"],
  ["2503", 5015, "SO"],
  ["2511", 4556, "SO"],
  ["2513", 4562, "SO"],
  ["2514", 4556, "SO"],
  ["2516", 4543, "SO"],
  ["2517", 4552, "SO"],
  ["2518", 4554, "SO"],
  ["2519", 4563, "SO"],
  ["2520", 4566, "SO"],
  ["2523", 4557, "SO"],
  ["2524", 4554, "SO"],
  ["2525", 4566, "SO"],
  ["2526", 4573, "SO"],
  ["2527", 4542, "SO"],
  ["2528", 4564, "SO"],
  ["2529", 4566, "SO"],
  ["2530", 4565, "SO"],
  ["2532", 4553, "SO"],
  ["2534", 4528, "SO"],
  ["2535", 4558, "SO"],
  ["2541", 4524, "SO"],
  ["2542", 4512, "SO"],
  ["2543", 2544, "SO"],
  ["2544", 4532, "SO"],
  ["2545", 4534, "SO"],
  ["2546", 2540, "SO"],
  ["2547", 4524, "SO"],
  ["2548", 4535, "SO"],
  ["2549", 4535, "SO"],
  ["2550", 4513, "SO"],
  ["2551", 4514, "SO"],
  ["2553", 4515, "SO"],
  ["2554", 4533, "SO"],
  ["2555", 4522, "SO"],
  ["2556", 2545, "SO"],
  ["2571", 4618, "SO"],
  ["2572", 4658, "SO"],
  ["2573", 4657, "SO"],
  ["2574", 5012, "SO"],
  ["2575", 4629, "SO"],
  ["2576", 5014, "SO"],
  ["2578", 4617, "SO"],
  ["2579", 4615, "SO"],
  ["2580", 4616, "SO"],
  ["2581", 4600, "SO"],
  ["2582", 4613, "SO"],
  ["2583", 5012, "SO"],
  ["2584", 4656, "SO"],
  ["2585", 5746, "SO"],
  ["2586", 4612, "SO"],
  ["2601", 4500, "SO"],
  ["2611", 4252, "SO"],
  ["2612", 4229, "SO"],
  ["2613", 4226, "SO"],
  ["2614", 4227, "SO"],
  ["2615", 4228, "SO"],
  ["2616", 4232, "SO"],
  ["2617", 4247, "SO"],
  ["2618", 4204, "SO"],
  ["2619", 4245, "SO"],
  ["2620", 4233, "SO"],
  ["2621", 4208, "SO"],
  ["2622", 4234, "SO"],
  ["2701", 4000, "BS"],
  ["2702", 4126, "BS"],
  ["2703", 4125, "BS"],
  ["2761", 4147, "BL"],
  ["2762", 4123, "BL"],
  ["2763", 4144, "BL"],
  ["2764", 4105, "BL"],
  ["2765", 4101, "BL"],
  ["2766", 4127, "BL"],
  ["2767", 4103, "BL"],
  ["2768", 4107, "BL"],
  ["2769", 4142, "BL"],
  ["2770", 4132, "BL"],
  ["2771", 4104, "BL"],
  ["2772", 4148, "BL"],
  ["2773", 4153, "BL"],
  ["2774", 4124, "BL"],
  ["2775", 4106, "BL"],
  ["2781", 4223, "BL"],
  ["2782", 4225, "BL"],
  ["2783", 4117, "BL"],
  ["2784", 4243, "BL"],
  ["2785", 4202, "BL"],
  ["2786", 4203, "BL"],
  ["2787", 4242, "BL"],
  ["2788", 4254, "BL"],
  ["2789", 4224, "BL"],
  ["2790", 2814, "BL"],
  ["2791", 4244, "BL"],
  ["2792", 4246, "BL"],
  ["2793", 4222, "BL"],
  ["2821", 4422, "BL"],
  ["2822", 4302, "BL"],
  ["2823", 4416, "BL"],
  ["2824", 4402, "BL"],
  ["2825", 4414, "BL"],
  ["2826", 4304, "BL"],
  ["2827", 4423, "BL"],
  ["2828", 4415, "BL"],
  ["2829", 4410, "BL"],
  ["2830", 4419, "BL"],
  ["2831", 4133, "BL"],
  ["2832", 4433, "BL"],
  ["2833", 4411, "BL"],
  ["2834", 4417, "BL"],
  ["2841", 4469, "BL"],
  ["2842", 4461, "BL"],
  ["2843", 4446, "BL"],
  ["2844", 4463, "BL"],
  ["2845", 4442, "BL"],
  ["2846", 4460, "BL"],
  ["2847", 4445, "BL"],
  ["2848", 4465, "BL"],
  ["2849", 4452, "BL"],
  ["2850", 4447, "BL"],
  ["2851", 4496, "BL"],
  ["2852", 4448, "BL"],
  ["2853", 4464, "BL"],
  ["2854", 4453, "BL"],
  ["2855", 4494, "BL"],
  ["2856", 4466, "BL"],
  ["2857", 4462, "BL"],
  ["2858", 4467, "BL"],
  ["2859", 4444, "BL"],
  ["2860", 4497, "BL"],
  ["2861", 4450, "BL"],
  ["2862", 4492, "BL"],
  ["2863", 4456, "BL"],
  ["2864", 4441, "BL"],
  ["2865", 4493, "BL"],
  ["2866", 4451, "BL"],
  ["2867", 4443, "BL"],
  ["2868", 4495, "BL"],
  ["2869", 4455, "BL"],
  ["2881", 4424, "BL"],
  ["2882", 4431, "BL"],
  ["2883", 4207, "BL"],
  ["2884", 4457, "BL"],
  ["2885", 4458, "BL"],
  ["2886", 4434, "BL"],
  ["2887", 4432, "BL"],
  ["2888", 4438, "BL"],
  ["2889", 4426, "BL"],
  ["2890", 4436, "BL"],
  ["2891", 4435, "BL"],
  ["2892", 4436, "BL"],
  ["2893", 4418, "BL"],
  ["2894", 4425, "BL"],
  ["2895", 4437, "BL"],
  ["2901", 8214, "SH"],
  ["2903", 8224, "SH"],
  ["2904", 8213, "SH"],
  ["2914", 8236, "SH"],
  ["2915", 8239, "SH"],
  ["2917", 8235, "SH"],
  ["2919", 8234, "SH"],
  ["2920", 8236, "SH"],
  ["2931", 8233, "SH"],
  ["2932", 8222, "SH"],
  ["2933", 8454, "SH"],
  ["2936", 8232, "SH"],
  ["2937", 8212, "SH"],
  ["2938", 8455, "SH"],
  ["2939", 8200, "SH"],
  ["2951", 8228, "SH"],
  ["2952", 8226, "SH"],
  ["2953", 8225, "SH"],
  ["2961", 8263, "SH"],
  ["2962", 8261, "SH"],
  ["2963", 8262, "SH"],
  ["2964", 8260, "SH"],
  ["2971", 8215, "SH"],
  ["2972", 8216, "SH"],
  ["2973", 8219, "SH"],
  ["2974", 8217, "SH"],
  ["3001", 9100, "AR"],
  ["3002", 9064, "AR"],
  ["3003", 9105, "AR"],
  ["3004", 9103, "AR"],
  ["3005", 9063, "AR"],
  ["3006", 9107, "AR"],
  ["3007", 9104, "AR"],
  ["3021", 9055, "AR"],
  ["3022", 9056, "AR"],
  ["3023", 9037, "AR"],
  ["3024", 9053, "AR"],
  ["3025", 9043, "AR"],
  ["3031", 9035, "AR"],
  ["3032", 9410, "AR"],
  ["3033", 9405, "AR"],
  ["3034", 9038, "AR"],
  ["3035", 9411, "AR"],
  ["3036", 9044, "AR"],
  ["3037", 9428, "AR"],
  ["3038", 9427, "AR"],
  ["3101", 9050, "AI"],
  ["3102", 9108, "AI"],
  ["3104", 9054, "AI"],
  ["3111", 9413, "AI"],
  ["3112", 9058, "AI"],
  ["3201", 9312, "SG"],
  ["3202", 9313, "SG"],
  ["3203", 9000, "SG"],
  ["3204", 9301, "SG"],
  ["3211", 9305, "SG"],
  ["3212", 9034, "SG"],
  ["3213", 9403, "SG"],
  ["3214", 9402, "SG"],
  ["3215", 9400, "SG"],
  ["3216", 9404, "SG"],
  ["3217", 9323, "SG"],
  ["3218", 9327, "SG"],
  ["3219", 9033, "SG"],
  ["3231", 9435, "SG"],
  ["3232", 9436, "SG"],
  ["3233", 9442, "SG"],
  ["3234", 9444, "SG"],
  ["3235", 9424, "SG"],
  ["3236", 9430, "SG"],
  ["3237", 9423, "SG"],
  ["3238", 9443, "SG"],
  ["3251", 9450, "SG"],
  ["3252", 9452, "SG"],
  ["3253", 9437, "SG"],
  ["3254", 9451, "SG"],
  ["3255", 9445, "SG"],
  ["3256", 9464, "SG"],
  ["3271", 9470, "SG"],
  ["3272", 9473, "SG"],
  ["3273", 9472, "SG"],
  ["3274", 9468, "SG"],
  ["3275", 9475, "SG"],
  ["3276", 9476, "SG"],
  ["3291", 7310, "SG"],
  ["3292", 8894, "SG"],
  ["3293", 7326, "SG"],
  ["3294", 7317, "SG"],
  ["3295", 8878, "SG"],
  ["3296", 7320, "SG"],
  ["3297", 7324, "SG"],
  ["3298", 8892, "SG"],
  ["3311", 8873, "SG"],
  ["3312", 8717, "SG"],
  ["3313", 8722, "SG"],
  ["3315", 8723, "SG"],
  ["3316", 8872, "SG"],
  ["3338", 8716, "SG"],
  ["3339", 8730, "SG"],
  ["3340", 8640, "SG"],
  ["3341", 8738, "SG"],
  ["3342", 8732, "SG"],
  ["3352", 9642, "SG"],
  ["3359", 9656, "SG"],
  ["3360", 9643, "SG"],
  ["3372", 9633, "SG"],
  ["3374", 9620, "SG"],
  ["3375", 9126, "SG"],
  ["3378", 9114, "SG"],
  ["3379", 9631, "SG"],
  ["3392", 9534, "SG"],
  ["3393", 9604, "SG"],
  ["3394", 9614, "SG"],
  ["3395", 9615, "SG"],
  ["3401", 9113, "SG"],
  ["3402", 9230, "SG"],
  ["3405", 9536, "SG"],
  ["3407", 9242, "SG"],
  ["3408", 9240, "SG"],
  ["3422", 9246, "SG"],
  ["3423", 9526, "SG"],
  ["3424", 9203, "SG"],
  ["3426", 9523, "SG"],
  ["3427", 9500, "SG"],
  ["3441", 9204, "SG"],
  ["3442", 9030, "SG"],
  ["3443", 9200, "SG"],
  ["3444", 9205, "SG"],
  ["3506", 7078, "GR"],
  ["3513", 7083, "GR"],
  ["3514", 7493, "GR"],
  ["3542", 7451, "GR"],
  ["3543", 7462, "GR"],
  ["3544", 7482, "GR"],
  ["3551", 7743, "GR"],
  ["3561", 7710, "GR"],
  ["3572", 7153, "GR"],
  ["3575", 7031, "GR"],
  ["3581", 7152, "GR"],
  ["3582", 7151, "GR"],
  ["3603", 7116, "GR"],
  ["3618", 7143, "GR"],
  ["3619", 7155, "GR"],
  ["3633", 7414, "GR"],
  ["3637", 7405, "GR"],
  ["3638", 7412, "GR"],
  ["3640", 7411, "GR"],
  ["3661", 7423, "GR"],
  ["3662", 7426, "GR"],
  ["3663", 7425, "GR"],
  ["3668", 7431, "GR"],
  ["3669", 7428, "GR"],
  ["3670", 7427, "GR"],
  ["3672", 7104, "GR"],
  ["3673", 7417, "GR"],
  ["3681", 7448, "GR"],
  ["3695", 7434, "GR"],
  ["3701", 7440, "GR"],
  ["3711", 7430, "GR"],
  ["3712", 7432, "GR"],
  ["3713", 7444, "GR"],
  ["3714", 7436, "GR"],
  ["3715", 7433, "GR"],
  ["3721", 7402, "GR"],
  ["3722", 7013, "GR"],
  ["3723", 7403, "GR"],
  ["3731", 7012, "GR"],
  ["3732", 7017, "GR"],
  ["3733", 7015, "GR"],
  ["3734", 7014, "GR"],
  ["3746", 7542, "GR"],
  ["3752", 7562, "GR"],
  ["3762", 7550, "GR"],
  ["3764", 7556, "GR"],
  ["3781", 7502, "GR"],
  ["3782", 7505, "GR"],
  ["3783", 7523, "GR"],
  ["3784", 7504, "GR"],
  ["3785", 7522, "GR"],
  ["3786", 7503, "GR"],
  ["3787", 7512, "GR"],
  ["3788", 7526, "GR"],
  ["3789", 7514, "GR"],
  ["3790", 7513, "GR"],
  ["3791", 7524, "GR"],
  ["3792", 7603, "GR"],
  ["3804", 6542, "GR"],
  ["3805", 6540, "GR"],
  ["3808", 6548, "GR"],
  ["3810", 6541, "GR"],
  ["3821", 6558, "GR"],
  ["3822", 6565, "GR"],
  ["3823", 6562, "GR"],
  ["3831", 6557, "GR"],
  ["3832", 6537, "GR"],
  ["3834", 6549, "GR"],
  ["3835", 6534, "GR"],
  ["3837", 6544, "GR"],
  ["3847", 7535, "GR"],
  ["3851", 7260, "GR"],
  ["3861", 7235, "GR"],
  ["3862", 7232, "GR"],
  ["3863", 7231, "GR"],
  ["3871", 7247, "GR"],
  ["3881", 7241, "GR"],
  ["3882", 7240, "GR"],
  ["3891", 7245, "GR"],
  ["3901", 7000, "GR"],
  ["3911", 7075, "GR"],
  ["3921", 7027, "GR"],
  ["3932", 7063, "GR"],
  ["3945", 7203, "GR"],
  ["3946", 7204, "GR"],
  ["3947", 7205, "GR"],
  ["3951", 7306, "GR"],
  ["3952", 7307, "GR"],
  ["3953", 7304, "GR"],
  ["3954", 7208, "GR"],
  ["3955", 7206, "GR"],
  ["3961", 7215, "GR"],
  ["3962", 7220, "GR"],
  ["3972", 7212, "GR"],
  ["3981", 7158, "GR"],
  ["3982", 7180, "GR"],
  ["3983", 7184, "GR"],
  ["3985", 7175, "GR"],
  ["3986", 7189, "GR"],
  ["3987", 7167, "GR"],
  ["3988", 7134, "GR"],
  ["4001", 5000, "AG"],
  ["4002", 5023, "AG"],
  ["4003", 5050, "AG"],
  ["4004", 5026, "AG"],
  ["4005", 5018, "AG"],
  ["4006", 5722, "AG"],
  ["4007", 5042, "AG"],
  ["4008", 5022, "AG"],
  ["4009", 5037, "AG"],
  ["4010", 5036, "AG"],
  ["4012", 5034, "AG"],
  ["4013", 5035, "AG"],
  ["4021", 5400, "AG"],
  ["4022", 5454, "AG"],
  ["4023", 8962, "AG"],
  ["4024", 5413, "AG"],
  ["4026", 5400, "AG"],
  ["4027", 5442, "AG"],
  ["4028", 5423, "AG"],
  ["4029", 5412, "AG"],
  ["4030", 8956, "AG"],
  ["4031", 5444, "AG"],
  ["4032", 5506, "AG"],
  ["4033", 5507, "AG"],
  ["4034", 5432, "AG"],
  ["4035", 5443, "AG"],
  ["4037", 5452, "AG"],
  ["4038", 5415, "AG"],
  ["4039", 5453, "AG"],
  ["4040", 8957, "AG"],
  ["4041", 5608, "AG"],
  ["4042", 5300, "AG"],
  ["4044", 5301, "AG"],
  ["4045", 5430, "AG"],
  ["4046", 5512, "AG"],
  ["4047", 5303, "AG"],
  ["4048", 5436, "AG"],
  ["4049", 5420, "AG"],
  ["4061", 8905, "AG"],
  ["4062", 8965, "AG"],
  ["4063", 5620, "AG"],
  ["4064", 5619, "AG"],
  ["4065", 5605, "AG"],
  ["4066", 5445, "AG"],
  ["4067", 5525, "AG"],
  ["4068", 5607, "AG"],
  ["4071", 8916, "AG"],
  ["4072", 5524, "AG"],
  ["4073", 8917, "AG"],
  ["4074", 8966, "AG"],
  ["4075", 8964, "AG"],
  ["4076", 5614, "AG"],
  ["4077", 5522, "AG"],
  ["4078", 5619, "AG"],
  ["4079", 8918, "AG"],
  ["4080", 5612, "AG"],
  ["4081", 8967, "AG"],
  ["4082", 5610, "AG"],
  ["4083", 5621, "AG"],
  ["4084", 8905, "AG"],
  ["4091", 5105, "AG"],
  ["4092", 5242, "AG"],
  ["4093", 5244, "AG"],
  ["4095", 5200, "AG"],
  ["4099", 5245, "AG"],
  ["4100", 5212, "AG"],
  ["4104", 5242, "AG"],
  ["4105", 5318, "AG"],
  ["4106", 5237, "AG"],
  ["4107", 5243, "AG"],
  ["4110", 5236, "AG"],
  ["4111", 5223, "AG"],
  ["4112", 5235, "AG"],
  ["4117", 5112, "AG"],
  ["4120", 5106, "AG"],
  ["4121", 5234, "AG"],
  ["4122", 5213, "AG"],
  ["4123", 5210, "AG"],
  ["4124", 5225, "AG"],
  ["4125", 5107, "AG"],
  ["4131", 5712, "AG"],
  ["4132", 5708, "AG"],
  ["4133", 5736, "AG"],
  ["4134", 5724, "AG"],
  ["4135", 5728, "AG"],
  ["4136", 5043, "AG"],
  ["4137", 5733, "AG"],
  ["4138", 5725, "AG"],
  ["4139", 5737, "AG"],
  ["4140", 5727, "AG"],
  ["4141", 5734, "AG"],
  ["4142", 5044, "AG"],
  ["4143", 5046, "AG"],
  ["4144", 5040, "AG"],
  ["4145", 5723, "AG"],
  ["4146", 5726, "AG"],
  ["4147", 5732, "AG"],
  ["4161", 5074, "AG"],
  ["4163", 5070, "AG"],
  ["4164", 5272, "AG"],
  ["4165", 5073, "AG"],
  ["4166", 5027, "AG"],
  ["4169", 5083, "AG"],
  ["4170", 5084, "AG"],
  ["4172", 4333, "AG"],
  ["4173", 5062, "AG"],
  ["4175", 5072, "AG"],
  ["4176", 5326, "AG"],
  ["4177", 4334, "AG"],
  ["4179", 5028, "AG"],
  ["4181", 5064, "AG"],
  ["4182", 5063, "AG"],
  ["4183", 5079, "AG"],
  ["4184", 5275, "AG"],
  ["4185", 5078, "AG"],
  ["4191", 5600, "AG"],
  ["4192", 5706, "AG"],
  ["4193", 5505, "AG"],
  ["4194", 5606, "AG"],
  ["4195", 5704, "AG"],
  ["4196", 5615, "AG"],
  ["4197", 5705, "AG"],
  ["4198", 5604, "AG"],
  ["4199", 5113, "AG"],
  ["4200", 5502, "AG"],
  ["4201", 5600, "AG"],
  ["4202", 5617, "AG"],
  ["4203", 5103, "AG"],
  ["4204", 5702, "AG"],
  ["4205", 5504, "AG"],
  ["4206", 5102, "AG"],
  ["4207", 5503, "AG"],
  ["4208", 5707, "AG"],
  ["4209", 5703, "AG"],
  ["4210", 5603, "AG"],
  ["4221", 5646, "AG"],
  ["4222", 5628, "AG"],
  ["4223", 5644, "AG"],
  ["4224", 5637, "AG"],
  ["4226", 5627, "AG"],
  ["4227", 5618, "AG"],
  ["4228", 5623, "AG"],
  ["4229", 5624, "AG"],
  ["4230", 5632, "AG"],
  ["4231", 6042, "AG"],
  ["4232", 5637, "AG"],
  ["4233", 5625, "AG"],
  ["4234", 5634, "AG"],
  ["4235", 5642, "AG"],
  ["4236", 5630, "AG"],
  ["4237", 5647, "AG"],
  ["4238", 8919, "AG"],
  ["4239", 5643, "AG"],
  ["4240", 5622, "AG"],
  ["4251", 4316, "AG"],
  ["4252", 4303, "AG"],
  ["4253", 4312, "AG"],
  ["4254", 4313, "AG"],
  ["4255", 4322, "AG"],
  ["4256", 4324, "AG"],
  ["4257", 4305, "AG"],
  ["4258", 4310, "AG"],
  ["4259", 4325, "AG"],
  ["4260", 4332, "AG"],
  ["4261", 4323, "AG"],
  ["4262", 4317, "AG"],
  ["4263", 4314, "AG"],
  ["4264", 4315, "AG"],
  ["4271", 4663, "AG"],
  ["4273", 4814, "AG"],
  ["4274", 4805, "AG"],
  ["4275", 5054, "AG"],
  ["4276", 5742, "AG"],
  ["4277", 5054, "AG"],
  ["4279", 4856, "AG"],
  ["4280", 4665, "AG"],
  ["4281", 5056, "AG"],
  ["4282", 4852, "AG"],
  ["4283", 5745, "AG"],
  ["4284", 5053, "AG"],
  ["4285", 4802, "AG"],
  ["4286", 4813, "AG"],
  ["4287", 4803, "AG"],
  ["4288", 5058, "AG"],
  ["4289", 4801, "AG"],
  ["4303", 5314, "AG"],
  ["4304", 5312, "AG"],
  ["4305", 5305, "AG"],
  ["4306", 5467, "AG"],
  ["4307", 5324, "AG"],
  ["4309", 5313, "AG"],
  ["4310", 5322, "AG"],
  ["4311", 5325, "AG"],
  ["4312", 5426, "AG"],
  ["4313", 5316, "AG"],
  ["4314", 5465, "AG"],
  ["4318", 5425, "AG"],
  ["4319", 5462, "AG"],
  ["4320", 5306, "AG"],
  ["4324", 5463, "AG"],
  ["4401", 9320, "TG"],
  ["4406", 8582, "TG"],
  ["4411", 9314, "TG"],
  ["4416", 8580, "TG"],
  ["4421", 9326, "TG"],
  ["4426", 8593, "TG"],
  ["4431", 9325, "TG"],
  ["4436", 8590, "TG"],
  ["4441", 8599, "TG"],
  ["4446", 8580, "TG"],
  ["4451", 8592, "TG"],
  ["4461", 8580, "TG"],
  ["4471", 9220, "TG"],
  ["4476", 8586, "TG"],
  ["4486", 9213, "TG"],
  ["4495", 9216, "TG"],
  ["4501", 9217, "TG"],
  ["4506", 8583, "TG"],
  ["4511", 8589, "TG"],
  ["4536", 8254, "TG"],
  ["4545", 8253, "TG"],
  ["4546", 8252, "TG"],
  ["4551", 8355, "TG"],
  ["4561", 8552, "TG"],
  ["4566", 8500, "TG"],
  ["4571", 8546, "TG"],
  ["4590", 8553, "TG"],
  ["4591", 9548, "TG"],
  ["4601", 8525, "TG"],
  ["4606", 9507, "TG"],
  ["4611", 8512, "TG"],
  ["4616", 8524, "TG"],
  ["4621", 8532, "TG"],
  ["4641", 8595, "TG"],
  ["4643", 8598, "TG"],
  ["4646", 8272, "TG"],
  ["4651", 8274, "TG"],
  ["4656", 8594, "TG"],
  ["4666", 8565, "TG"],
  ["4671", 8280, "TG"],
  ["4681", 8585, "TG"],
  ["4683", 8574, "TG"],
  ["4691", 8596, "TG"],
  ["4696", 8274, "TG"],
  ["4701", 8564, "TG"],
  ["4711", 9562, "TG"],
  ["4716", 9553, "TG"],
  ["4721", 8362, "TG"],
  ["4723", 9502, "TG"],
  ["4724", 8360, "TG"],
  ["4726", 8374, "TG"],
  ["4741", 9506, "TG"],
  ["4746", 9543, "TG"],
  ["4751", 9532, "TG"],
  ["4756", 8577, "TG"],
  ["4761", 8370, "TG"],
  ["4776", 9555, "TG"],
  ["4781", 9545, "TG"],
  ["4786", 9535, "TG"],
  ["4791", 9514, "TG"],
  ["4801", 8267, "TG"],
  ["4806", 8264, "TG"],
  ["4811", 8506, "TG"],
  ["4816", 8508, "TG"],
  ["4821", 8537, "TG"],
  ["4826", 8265, "TG"],
  ["4831", 8555, "TG"],
  ["4841", 8505, "TG"],
  ["4846", 8558, "TG"],
  ["4851", 8268, "TG"],
  ["4864", 8266, "TG"],
  ["4871", 8259, "TG"],
  ["4881", 8514, "TG"],
  ["4891", 8572, "TG"],
  ["4901", 8585, "TG"],
  ["4911", 8575, "TG"],
  ["4921", 9565, "TG"],
  ["4941", 8561, "TG"],
  ["4946", 8570, "TG"],
  ["4951", 8554, "TG"],
  ["5001", 6532, "TI"],
  ["5002", 6500, "TI"],
  ["5003", 6593, "TI"],
  ["5009", 6810, "TI"],
  ["5010", 6533, "TI"],
  ["5017", 6592, "TI"],
  ["5048", 6723, "TI"],
  ["5049", 6720, "TI"],
  ["5050", 6714, "TI"],
  ["5061", 6780, "TI"],
  ["5063", 6781, "TI"],
  ["5064", 6743, "TI"],
  ["5071", 6774, "TI"],
  ["5072", 6747, "TI"],
  ["5073", 6745, "TI"],
  ["5076", 6744, "TI"],
  ["5077", 6742, "TI"],
  ["5078", 6773, "TI"],
  ["5079", 6777, "TI"],
  ["5091", 6612, "TI"],
  ["5096", 6645, "TI"],
  ["5097", 6614, "TI"],
  ["5108", 6596, "TI"],
  ["5112", 6633, "TI"],
  ["5113", 6600, "TI"],
  ["5115", 6616, "TI"],
  ["5117", 6647, "TI"],
  ["5118", 6648, "TI"],
  ["5120", 6600, "TI"],
  ["5121", 6644, "TI"],
  ["5125", 6613, "TI"],
  ["5131", 6598, "TI"],
  ["5136", 6664, "TI"],
  ["5138", 6516, "TI"],
  ["5141", 6982, "TI"],
  ["5143", 6994, "TI"],
  ["5144", 6822, "TI"],
  ["5146", 6999, "TI"],
  ["5148", 6930, "TI"],
  ["5149", 6981, "TI"],
  ["5151", 6935, "TI"],
  ["5154", 6816, "TI"],
  ["5160", 6827, "TI"],
  ["5161", 6936, "TI"],
  ["5162", 6814, "TI"],
  ["5167", 6952, "TI"],
  ["5171", 6987, "TI"],
  ["5176", 6949, "TI"],
  ["5180", 6944, "TI"],
  ["5181", 6981, "TI"],
  ["5186", 6916, "TI"],
  ["5187", 6929, "TI"],
  ["5189", 6814, "TI"],
  ["5192", 6900, "TI"],
  ["5193", 6983, "TI"],
  ["5194", 6928, "TI"],
  ["5196", 6900, "TI"],
  ["5198", 6815, "TI"],
  ["5199", 6805, "TI"],
  ["5200", 6986, "TI"],
  ["5203", 6922, "TI"],
  ["5205", 6933, "TI"],
  ["5206", 6991, "TI"],
  ["5207", 6986, "TI"],
  ["5208", 6945, "TI"],
  ["5210", 6900, "TI"],
  ["5212", 6946, "TI"],
  ["5214", 6948, "TI"],
  ["5216", 6984, "TI"],
  ["5221", 6942, "TI"],
  ["5225", 6924, "TI"],
  ["5226", 6947, "TI"],
  ["5227", 6808, "TI"],
  ["5230", 6992, "TI"],
  ["5231", 6943, "TI"],
  ["5233", 6921, "TI"],
  ["5236", 6926, "TI"],
  ["5237", 6939, "TI"],
  ["5238", 6803, "TI"],
  ["5239", 6980, "TI"],
  ["5240", 6817, "TI"],
  ["5242", 6828, "TI"],
  ["5249", 6875, "TI"],
  ["5250", 6830, "TI"],
  ["5251", 6877, "TI"],
  ["5254", 6825, "TI"],
  ["5257", 6834, "TI"],
  ["5260", 6883, "TI"],
  ["5263", 6826, "TI"],
  ["5266", 6855, "TI"],
  ["5268", 6833, "TI"],
  ["5269", 6839, "TI"],
  ["5281", 6710, "TI"],
  ["5287", 6707, "TI"],
  ["5304", 6685, "TI"],
  ["5307", 6683, "TI"],
  ["5309", 6683, "TI"],
  ["5310", 6675, "TI"],
  ["5315", 6682, "TI"],
  ["5317", 6678, "TI"],
  ["5323", 6693, "TI"],
  ["5324", 6670, "TI"],
  ["5396", 6652, "TI"],
  ["5397", 6658, "TI"],
  ["5398", 6594, "TI"],
  ["5399", 6636, "TI"],
  ["5401", 1860, "VD"],
  ["5402", 1880, "VD"],
  ["5403", 1846, "VD"],
  ["5404", 1856, "VD"],
  ["5405", 1882, "VD"],
  ["5406", 1892, "VD"],
  ["5407", 1854, "VD"],
  ["5408", 1845, "VD"],
  ["5409", 1884, "VD"],
  ["5410", 1866, "VD"],
  ["5411", 1864, "VD"],
  ["5412", 1847, "VD"],
  ["5413", 1852, "VD"],
  ["5414", 1844, "VD"],
  ["5415", 1853, "VD"],
  ["5422", 1174, "VD"],
  ["5423", 1144, "VD"],
  ["5424", 1149, "VD"],
  ["5425", 1145, "VD"],
  ["5426", 1172, "VD"],
  ["5427", 1173, "VD"],
  ["5428", 1188, "VD"],
  ["5429", 1261, "VD"],
  ["5430", 1261, "VD"],
  ["5431", 1146, "VD"],
  ["5434", 1188, "VD"],
  ["5435", 1176, "VD"],
  ["5436", 1187, "VD"],
  ["5437", 1189, "VD"],
  ["5451", 1580, "VD"],
  ["5456", 1588, "VD"],
  ["5458", 1595, "VD"],
  ["5464", 1586, "VD"],
  ["5471", 1042, "VD"],
  ["5472", 1035, "VD"],
  ["5473", 1034, "VD"],
  ["5474", 1308, "VD"],
  ["5475", 1148, "VD"],
  ["5476", 1316, "VD"],
  ["5477", 1304, "VD"],
  ["5479", 1148, "VD"],
  ["5480", 1306, "VD"],
  ["5481", 1304, "VD"],
  ["5482", 1300, "VD"],
  ["5483", 1313, "VD"],
  ["5484", 1124, "VD"],
  ["5485", 1117, "VD"],
  ["5486", 1148, "VD"],
  ["5487", 1307, "VD"],
  ["5488", 1148, "VD"],
  ["5489", 1031, "VD"],
  ["5490", 1148, "VD"],
  ["5491", 1148, "VD"],
  ["5492", 1147, "VD"],
  ["5493", 1317, "VD"],
  ["5495", 1305, "VD"],
  ["5496", 1303, "VD"],
  ["5497", 1318, "VD"],
  ["5498", 1315, "VD"],
  ["5499", 1304, "VD"],
  ["5501", 1036, "VD"],
  ["5503", 1302, "VD"],
  ["5511", 1042, "VD"],
  ["5512", 1038, "VD"],
  ["5514", 1041, "VD"],
  ["5515", 1053, "VD"],
  ["5516", 1053, "VD"],
  ["5518", 1040, "VD"],
  ["5520", 1417, "VD"],
  ["5521", 1037, "VD"],
  ["5522", 1044, "VD"],
  ["5523", 1055, "VD"],
  ["5527", 1054, "VD"],
  ["5529", 1377, "VD"],
  ["5530", 1416, "VD"],
  ["5531", 1375, "VD"],
  ["5533", 1041, "VD"],
  ["5534", 1046, "VD"],
  ["5535", 1040, "VD"],
  ["5537", 1040, "VD"],
  ["5539", 1418, "VD"],
  ["5540", 1041, "VD"],
  ["5541", 1376, "VD"],
  ["5551", 1427, "VD"],
  ["5552", 1452, "VD"],
  ["5553", 1424, "VD"],
  ["5554", 1426, "VD"],
  ["5555", 1426, "VD"],
  ["5556", 1420, "VD"],
  ["5557", 1421, "VD"],
  ["5559", 1429, "VD"],
  ["5560", 1421, "VD"],
  ["5561", 1422, "VD"],
  ["5562", 1453, "VD"],
  ["5563", 1428, "VD"],
  ["5564", 1431, "VD"],
  ["5565", 1425, "VD"],
  ["5566", 1428, "VD"],
  ["5568", 1450, "VD"],
  ["5571", 1423, "VD"],
  ["5581", 1092, "VD"],
  ["5582", 1033, "VD"],
  ["5583", 1023, "VD"],
  ["5584", 1066, "VD"],
  ["5585", 1008, "VD"],
  ["5586", 1000, "VD"],
  ["5587", 1052, "VD"],
  ["5588", 1094, "VD"],
  ["5589", 1008, "VD"],
  ["5590", 1009, "VD"],
  ["5591", 1020, "VD"],
  ["5592", 1032, "VD"],
  ["5601", 1071, "VD"],
  ["5604", 1072, "VD"],
  ["5606", 1093, "VD"],
  ["5607", 1070, "VD"],
  ["5609", 1071, "VD"],
  ["5610", 1071, "VD"],
  ["5611", 1073, "VD"],
  ["5613", 1096, "VD"],
  ["5621", 1123, "VD"],
  ["5622", 1121, "VD"],
  ["5623", 1164, "VD"],
  ["5624", 1030, "VD"],
  ["5627", 1022, "VD"],
  ["5628", 1134, "VD"],
  ["5629", 1127, "VD"],
  ["5631", 1135, "VD"],
  ["5632", 1026, "VD"],
  ["5633", 1026, "VD"],
  ["5634", 1112, "VD"],
  ["5635", 1000, "VD"],
  ["5636", 1163, "VD"],
  ["5637", 1175, "VD"],
  ["5638", 1027, "VD"],
  ["5639", 1132, "VD"],
  ["5640", 1167, "VD"],
  ["5642", 1110, "VD"],
  ["5643", 1028, "VD"],
  ["5645", 1122, "VD"],
  ["5646", 1162, "VD"],
  ["5648", 1025, "VD"],
  ["5649", 1131, "VD"],
  ["5650", 1126, "VD"],
  ["5651", 1029, "VD"],
  ["5652", 1168, "VD"],
  ["5653", 1134, "VD"],
  ["5654", 1115, "VD"],
  ["5655", 1169, "VD"],
  ["5656", 1128, "VD"],
  ["5661", 1063, "VD"],
  ["5663", 1514, "VD"],
  ["5665", 1512, "VD"],
  ["5669", 1521, "VD"],
  ["5671", 1682, "VD"],
  ["5673", 1513, "VD"],
  ["5674", 1682, "VD"],
  ["5675", 1526, "VD"],
  ["5678", 1510, "VD"],
  ["5680", 1045, "VD"],
  ["5683", 1682, "VD"],
  ["5684", 1513, "VD"],
  ["5688", 1510, "VD"],
  ["5690", 1515, "VD"],
  ["5692", 1509, "VD"],
  ["5693", 1410, "VD"],
  ["5701", 1277, "VD"],
  ["5702", 1273, "VD"],
  ["5703", 1269, "VD"],
  ["5704", 1268, "VD"],
  ["5705", 1279, "VD"],
  ["5706", 1277, "VD"],
  ["5707", 1279, "VD"],
  ["5708", 1290, "VD"],
  ["5709", 1275, "VD"],
  ["5710", 1267, "VD"],
  ["5711", 1291, "VD"],
  ["5712", 1296, "VD"],
  ["5713", 1299, "VD"],
  ["5714", 1263, "VD"],
  ["5715", 1266, "VD"],
  ["5716", 1262, "VD"],
  ["5717", 1297, "VD"],
  ["5718", 1272, "VD"],
  ["5719", 1276, "VD"],
  ["5720", 1271, "VD"],
  ["5721", 1196, "VD"],
  ["5722", 1274, "VD"],
  ["5723", 1295, "VD"],
  ["5724", 1260, "VD"],
  ["5725", 1197, "VD"],
  ["5726", 1278, "VD"],
  ["5727", 1264, "VD"],
  ["5728", 1274, "VD"],
  ["5729", 1295, "VD"],
  ["5730", 1270, "VD"],
  ["5731", 1261, "VD"],
  ["5732", 1267, "VD"],
  ["5741", 1355, "VD"],
  ["5742", 1352, "VD"],
  ["5743", 1321, "VD"],
  ["5744", 1338, "VD"],
  ["5745", 1446, "VD"],
  ["5746", 1372, "VD"],
  ["5747", 1353, "VD"],
  ["5748", 1329, "VD"],
  ["5749", 1374, "VD"],
  ["5750", 1356, "VD"],
  ["5752", 1322, "VD"],
  ["5754", 1326, "VD"],
  ["5755", 1357, "VD"],
  ["5756", 1354, "VD"],
  ["5757", 1350, "VD"],
  ["5758", 1148, "VD"],
  ["5759", 1324, "VD"],
  ["5760", 1439, "VD"],
  ["5761", 1323, "VD"],
  ["5762", 1355, "VD"],
  ["5763", 1358, "VD"],
  ["5764", 1337, "VD"],
  ["5765", 1325, "VD"],
  ["5766", 1445, "VD"],
  ["5785", 1082, "VD"],
  ["5790", 1613, "VD"],
  ["5792", 1081, "VD"],
  ["5798", 1088, "VD"],
  ["5799", 1080, "VD"],
  ["5803", 1085, "VD"],
  ["5804", 1041, "VD"],
  ["5805", 1610, "VD"],
  ["5806", 1083, "VD"],
  ["5812", 1537, "VD"],
  ["5813", 1545, "VD"],
  ["5816", 1562, "VD"],
  ["5817", 1543, "VD"],
  ["5819", 1525, "VD"],
  ["5821", 1565, "VD"],
  ["5822", 1530, "VD"],
  ["5827", 1552, "VD"],
  ["5828", 1538, "VD"],
  ["5830", 1554, "VD"],
  ["5831", 1536, "VD"],
  ["5841", 1660, "VD"],
  ["5842", 1658, "VD"],
  ["5843", 1659, "VD"],
  ["5851", 1165, "VD"],
  ["5852", 1195, "VD"],
  ["5853", 1183, "VD"],
  ["5854", 1268, "VD"],
  ["5855", 1195, "VD"],
  ["5856", 1186, "VD"],
  ["5857", 1182, "VD"],
  ["5858", 1184, "VD"],
  ["5859", 1185, "VD"],
  ["5860", 1166, "VD"],
  ["5861", 1180, "VD"],
  ["5862", 1180, "VD"],
  ["5863", 1184, "VD"],
  ["5871", 1344, "VD"],
  ["5872", 1348, "VD"],
  ["5873", 1345, "VD"],
  ["5882", 1801, "VD"],
  ["5883", 1802, "VD"],
  ["5884", 1804, "VD"],
  ["5885", 1805, "VD"],
  ["5886", 1817, "VD"],
  ["5889", 1814, "VD"],
  ["5890", 1800, "VD"],
  ["5891", 1820, "VD"],
  ["5892", 1807, "VD"],
  ["5902", 1432, "VD"],
  ["5903", 1407, "VD"],
  ["5904", 1436, "VD"],
  ["5905", 1443, "VD"],
  ["5907", 1464, "VD"],
  ["5908", 1464, "VD"],
  ["5909", 1400, "VD"],
  ["5910", 1406, "VD"],
  ["5911", 1404, "VD"],
  ["5912", 1415, "VD"],
  ["5913", 1407, "VD"],
  ["5914", 1434, "VD"],
  ["5919", 1438, "VD"],
  ["5921", 1415, "VD"],
  ["5922", 1442, "VD"],
  ["5923", 1047, "VD"],
  ["5924", 1430, "VD"],
  ["5925", 1413, "VD"],
  ["5926", 1405, "VD"],
  ["5928", 1463, "VD"],
  ["5929", 1433, "VD"],
  ["5930", 1437, "VD"],
  ["5931", 1436, "VD"],
  ["5932", 1412, "VD"],
  ["5933", 1441, "VD"],
  ["5934", 1412, "VD"],
  ["5935", 1404, "VD"],
  ["5937", 1431, "VD"],
  ["5938", 1400, "VD"],
  ["5939", 1462, "VD"],
  ["6002", 3902, "VS"],
  ["6004", 3939, "VS"],
  ["6007", 3903, "VS"],
  ["6008", 3911, "VS"],
  ["6009", 3907, "VS"],
  ["6010", 3913, "VS"],
  ["6011", 3907, "VS"],
  ["6021", 1957, "VS"],
  ["6022", 1955, "VS"],
  ["6023", 1975, "VS"],
  ["6024", 1996, "VS"],
  ["6025", 1963, "VS"],
  ["6032", 1946, "VS"],
  ["6033", 1945, "VS"],
  ["6034", 1944, "VS"],
  ["6035", 1933, "VS"],
  ["6037", 1947, "VS"],
  ["6052", 3997, "VS"],
  ["6054", 3996, "VS"],
  ["6056", 3995, "VS"],
  ["6057", 3984, "VS"],
  ["6058", 3801, "VS"],
  ["6061", 3994, "VS"],
  ["6076", 3999, "VS"],
  ["6077", 3998, "VS"],
  ["6082", 1966, "VS"],
  ["6083", 1985, "VS"],
  ["6084", 1982, "VS"],
  ["6087", 1969, "VS"],
  ["6089", 1981, "VS"],
  ["6090", 1968, "VS"],
  ["6101", 3951, "VS"],
  ["6102", 3955, "VS"],
  ["6104", 3946, "VS"],
  ["6109", 3953, "VS"],
  ["6110", 3957, "VS"],
  ["6111", 3954, "VS"],
  ["6112", 3948, "VS"],
  ["6113", 3970, "VS"],
  ["6116", 3953, "VS"],
  ["6117", 3956, "VS"],
  ["6118", 3945, "VS"],
  ["6119", 3948, "VS"],
  ["6131", 1932, "VS"],
  ["6133", 1926, "VS"],
  ["6134", 1914, "VS"],
  ["6135", 1912, "VS"],
  ["6136", 1920, "VS"],
  ["6137", 1928, "VS"],
  ["6139", 1918, "VS"],
  ["6140", 1913, "VS"],
  ["6141", 1907, "VS"],
  ["6142", 1929, "VS"],
  ["6151", 1874, "VS"],
  ["6152", 1893, "VS"],
  ["6153", 1870, "VS"],
  ["6154", 1897, "VS"],
  ["6155", 1898, "VS"],
  ["6156", 1872, "VS"],
  ["6157", 1873, "VS"],
  ["6158", 1895, "VS"],
  ["6159", 1896, "VS"],
  ["6172", 3983, "VS"],
  ["6173", 3982, "VS"],
  ["6177", 3993, "VS"],
  ["6181", 3987, "VS"],
  ["6191", 3938, "VS"],
  ["6192", 3919, "VS"],
  ["6193", 3935, "VS"],
  ["6194", 3943, "VS"],
  ["6195", 3917, "VS"],
  ["6197", 3917, "VS"],
  ["6198", 3942, "VS"],
  ["6199", 3942, "VS"],
  ["6201", 3944, "VS"],
  ["6202", 3918, "VS"],
  ["6203", 3983, "VS"],
  ["6204", 3940, "VS"],
  ["6205", 3991, "VS"],
  ["6211", 1903, "VS"],
  ["6212", 1905, "VS"],
  ["6213", 1902, "VS"],
  ["6214", 1925, "VS"],
  ["6215", 1869, "VS"],
  ["6217", 1890, "VS"],
  ["6218", 1923, "VS"],
  ["6219", 1904, "VS"],
  ["6220", 1891, "VS"],
  ["6232", 3966, "VS"],
  ["6235", 3965, "VS"],
  ["6238", 3979, "VS"],
  ["6239", 1977, "VS"],
  ["6240", 3963, "VS"],
  ["6246", 1958, "VS"],
  ["6248", 3976, "VS"],
  ["6252", 3961, "VS"],
  ["6253", 3960, "VS"],
  ["6254", 3968, "VS"],
  ["6261", 1974, "VS"],
  ["6263", 1971, "VS"],
  ["6265", 1965, "VS"],
  ["6266", 1950, "VS"],
  ["6267", 1993, "VS"],
  ["6281", 3937, "VS"],
  ["6282", 3922, "VS"],
  ["6283", 3926, "VS"],
  ["6285", 3925, "VS"],
  ["6286", 3931, "VS"],
  ["6287", 3928, "VS"],
  ["6288", 3905, "VS"],
  ["6289", 3908, "VS"],
  ["6290", 3906, "VS"],
  ["6291", 3910, "VS"],
  ["6292", 3927, "VS"],
  ["6293", 3922, "VS"],
  ["6294", 3933, "VS"],
  ["6295", 3929, "VS"],
  ["6296", 3923, "VS"],
  ["6297", 3930, "VS"],
  ["6298", 3932, "VS"],
  ["6299", 3934, "VS"],
  ["6300", 3920, "VS"],
  ["6404", 2015, "NE"],
  ["6408", 2016, "NE"],
  ["6413", 2149, "NE"],
  ["6416", 2013, "NE"],
  ["6417", 2023, "NE"],
  ["6421", 2300, "NE"],
  ["6422", 2325, "NE"],
  ["6423", 2314, "NE"],
  ["6432", 2406, "NE"],
  ["6433", 2318, "NE"],
  ["6434", 2414, "NE"],
  ["6435", 2405, "NE"],
  ["6436", 2400, "NE"],
  ["6437", 2316, "NE"],
  ["6451", 2087, "NE"],
  ["6452", 2088, "NE"],
  ["6453", 2073, "NE"],
  ["6454", 2068, "NE"],
  ["6455", 2525, "NE"],
  ["6456", 2523, "NE"],
  ["6458", 2000, "NE"],
  ["6459", 2072, "NE"],
  ["6461", 2074, "NE"],
  ["6487", 2043, "NE"],
  ["6504", 2117, "NE"],
  ["6511", 2126, "NE"],
  ["6512", 2105, "NE"],
  ["6601", 1288, "GE"],
  ["6602", 1247, "GE"],
  ["6603", 1237, "GE"],
  ["6604", 1285, "GE"],
  ["6605", 1257, "GE"],
  ["6606", 1293, "GE"],
  ["6607", 1233, "GE"],
  ["6608", 1227, "GE"],
  ["6609", 1236, "GE"],
  ["6610", 1298, "GE"],
  ["6611", 1284, "GE"],
  ["6612", 1224, "GE"],
  ["6613", 1225, "GE"],
  ["6614", 1244, "GE"],
  ["6615", 1239, "GE"],
  ["6616", 1222, "GE"],
  ["6617", 1223, "GE"],
  ["6618", 1232, "GE"],
  ["6619", 1246, "GE"],
  ["6620", 1283, "GE"],
  ["6621", 1200, "GE"],
  ["6622", 1294, "GE"],
  ["6623", 1200, "GE"],
  ["6624", 1251, "GE"],
  ["6625", 1248, "GE"],
  ["6626", 1254, "GE"],
  ["6627", 1287, "GE"],
  ["6628", 1200, "GE"],
  ["6629", 1252, "GE"],
  ["6630", 1200, "GE"],
  ["6631", 1213, "GE"],
  ["6632", 1258, "GE"],
  ["6633", 1228, "GE"],
  ["6634", 1292, "GE"],
  ["6635", 1243, "GE"],
  ["6636", 1241, "GE"],
  ["6637", 1281, "GE"],
  ["6638", 1242, "GE"],
  ["6639", 1286, "GE"],
  ["6640", 1226, "GE"],
  ["6641", 1256, "GE"],
  ["6642", 1253, "GE"],
  ["6643", 1200, "GE"],
  ["6644", 1290, "GE"],
  ["6645", 1234, "GE"],
  ["6702", 2856, "JU"],
  ["6703", 2803, "JU"],
  ["6704", 2843, "JU"],
  ["6706", 2825, "JU"],
  ["6708", 2832, "JU"],
  ["6709", 2823, "JU"],
  ["6710", 2852, "JU"],
  ["6711", 2800, "JU"],
  ["6712", 2802, "JU"],
  ["6713", 2813, "JU"],
  ["6715", 2827, "JU"],
  ["6716", 2806, "JU"],
  ["6718", 2812, "JU"],
  ["6719", 2807, "JU"],
  ["6721", 2842, "JU"],
  ["6722", 2873, "JU"],
  ["6724", 2805, "JU"],
  ["6729", 2853, "JU"],
  ["6730", 2826, "JU"],
  ["6741", 2360, "JU"],
  ["6742", 2336, "JU"],
  ["6743", 2345, "JU"],
  ["6744", 2345, "JU"],
  ["6745", 2363, "JU"],
  ["6748", 2714, "JU"],
  ["6750", 2718, "JU"],
  ["6751", 2362, "JU"],
  ["6753", 2338, "JU"],
  ["6754", 2340, "JU"],
  ["6757", 2350, "JU"],
  ["6758", 2364, "JU"],
  ["6759", 2887, "JU"],
  ["6771", 2942, "JU"],
  ["6773", 2935, "JU"],
  ["6774", 2926, "JU"],
  ["6775", 2944, "JU"],
  ["6778", 2915, "JU"],
  ["6781", 2932, "JU"],
  ["6782", 2952, "JU"],
  ["6783", 2922, "JU"],
  ["6784", 2950, "JU"],
  ["6785", 2905, "JU"],
  ["6787", 2933, "JU"],
  ["6789", 2916, "JU"],
  ["6790", 2903, "JU"],
  ["6792", 2908, "JU"],
  ["6793", 2933, "JU"],
  ["6800", 2900, "JU"],
  ["6806", 2943, "JU"],
  ["6807", 2925, "JU"],
  ["6808", 2883, "JU"],
  ["6809", 2907, "JU"],
  ["6810", 2954, "JU"],
  ["7001", 9490, "FL"],
  ["7002", 9495, "FL"],
  ["7003", 9496, "FL"],
  ["7004", 9497, "FL"],
  ["7005", 9494, "FL"],
  ["7006", 9498, "FL"],
  ["7007", 9492, "FL"],
  ["7008", 9486, "FL"],
  ["7009", 9487, "FL"],
  ["7010", 9491, "FL"],
  ["7011", 9488, "FL"],
  ["7101", 8238, "DE"],
  ["7301", 6911, "IT"],
]
