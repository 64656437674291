import { qs } from "/js/utils/dom"
import { getCookie, setCookie } from "/js/utils/cookies"

const cookieName = "lm"

export function initLeadMagnet() {
  if (getCookie(cookieName)) return

  const dialog = qs("[data-lead-magnet]")
  if (dialog) {
    dialog.showModal()
    const form = qs("form", dialog)
    form.addEventListener("submit", async () => {
      const body = new FormData(form)
      const response = await fetch(form.action, {
        method: "POST",
        body,
      })
      alert(await response.text())
      // Submitted, show again in 30 days
      setCookie("lm", "seen", 86400 * 30)
      dialog.close()
    })

    const close = () => {
      dialog.close()
      // Closed, show again in 24 hours
      setCookie("lm", "seen", 86400)
    }

    qs("[data-lead-magnet-close]", dialog).addEventListener("click", close)

    document.body.addEventListener("keydown", (e) => {
      if (e.key === "Escape" && dialog.open) {
        close()
      }
    })
  }
}
