export function initToggleNav() {
  const body = document.body
  const toggleOpen = document.querySelector("[data-menu-toggle-open]")
  const toggleClose = document.querySelector("[data-menu-toggle-close]")
  const mobileMenu = document.querySelector(".mobile-menu")

  toggleOpen.addEventListener("click", openMenu)
  toggleClose.addEventListener("click", closeMenu)

  function openMenu(e) {
    e && e.preventDefault()
    mobileMenu.setAttribute("aria-expanded", "true")
    if (!body.classList.contains("menu-is-open"))
      body.classList.add("menu-is-open")
  }

  function closeMenu(e) {
    e && e.preventDefault()
    mobileMenu.setAttribute("aria-expanded", "false")
    body.classList.remove("menu-is-open")
  }

  body.addEventListener("keydown", (e) => {
    if (e.key === "Escape" && body.classList.contains("menu-is-open")) {
      closeMenu()
    }
  })
}

export function initToggleLangNav() {
  const toggle = document.querySelector(".language-nav .language-nav-toggle")
  const langMenu = document.querySelector(".language-nav")

  toggle.addEventListener("click", toogleLangMenu)

  function toogleLangMenu(e) {
    e.preventDefault()
    if (langMenu.classList.contains("open")) {
      langMenu.classList.remove("open")
      toggle.setAttribute("aria-expanded", "false")
    } else {
      langMenu.classList.add("open")
      toggle.setAttribute("aria-expanded", "true")
    }
  }
}

export function initScrolledNav() {
  const handleScroll = () => {
    document.body.classList.toggle("scrolled", window.scrollY > 16)
  }
  handleScroll()
  window.addEventListener("scroll", handleScroll, { passive: true })
}
