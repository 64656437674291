const theme = {
  screens: { md: "48rem", lg: "64rem" },
  testimonialDimensions: {
    square: {
      width: "600",
      height: "600",
    },
  },
}

module.exports = {
  customMedia: {
    "--media-sm-only": `screen and (max-width: calc(${theme.screens.md} - 1px))`,
    "--media-md": `screen and (min-width: ${theme.screens.md})`,
    "--media-lg": `screen and (min-width: ${theme.screens.lg})`,
  },
  theme,
}
