/* global plausible */

export function initPlausibleForLanguageSites() {
  window.plausible =
    window.plausible ||
    function () {
      window.plausible.q = (window.plausible.q || []).push(arguments)
    }
  const origin = window.location.origin,
    lang = document.documentElement.lang,
    path = window.location.pathname
  window.plausiblePath = `${origin}/${lang}${path}`
  plausible("pageview", { u: window.plausiblePath + window.location.search })
}

export function initPlausibleCustomEvents() {
  if (plausible)
    document.body.addEventListener("click", (e) => {
      const button = e.target.closest(".plausible.button")
      if (button)
        plausible("ButtonClick", {
          props: { href: button.href, label: button.dataset.label },
        })
    })
}
