export function getCookie(cookieName) {
  const cookies = document.cookie ? document.cookie.split("; ") : []
  const prefix = `${cookieName}=`
  for (let cookie of cookies) {
    if (cookie.startsWith(prefix))
      return decodeURIComponent(cookie.substring(prefix.length))
  }
}

export function setCookie(cookieName, value, age = 30 * 86400) {
  const cookie = `${cookieName}=${value};max-age=${age};path=/;sameSite=Strict`
  document.cookie = cookie
}
