import { qs, qsa } from "/js/utils/dom"
import { NAMES } from "/js/lib/bfs"

export const initSignatureForm = () => {
  // switch pages
  const signatureForm = qs("#signature-form")
  const signatureFormPages = qsa(".signature-form__page")
  const signatureFormNextButton = qs(".signature-form__next")

  if (signatureForm && signatureFormPages && signatureFormNextButton) {
    signatureFormNextButton.addEventListener("click", (e) => {
      const currentPage = e.target.closest(".signature-form__page")
      if (qsa("input", currentPage).every((input) => input.checkValidity())) {
        const currentPageIndex = signatureFormPages.indexOf(currentPage)
        signatureFormPages.forEach((page, i) => {
          if (i == currentPageIndex + 1) page.classList.remove("hidden")
          else page.classList.add("hidden")
        })
      } else signatureForm.reportValidity()
    })
  }

  // filter bfs name choices
  const bfsCantonField = qs("#id_bfs_canton")
  const bfsNameField = qs("#id_bfs_name")
  const bfsPostalCodeField = qs("#id_bfs_postal_code")

  const filterNames = (selection) => {
    const options = qsa("option", bfsNameField)
    bfsNameField.value = ""
    options.forEach((option) => {
      const reference = NAMES.find((choice) => choice[0] === option.value)
      if (reference && reference[2] !== selection)
        option.classList.add("hidden")
      else option.classList.remove("hidden")
    })
  }

  const setPostalCode = (selection) => {
    bfsPostalCodeField.value = selection
      ? NAMES.find((name) => name[0] === selection)[1]
      : ""
  }

  if (bfsNameField && bfsCantonField && bfsPostalCodeField) {
    filterNames(bfsCantonField.value)
    setPostalCode(bfsNameField.value)
    bfsCantonField.addEventListener("change", (e) => {
      const selection = e.target.value
      filterNames(selection)
    })
    bfsNameField.addEventListener("change", (e) => {
      const selection = e.target.value
      setPostalCode(selection)
    })
  }

  // enable country selection
  const countryDiv = qs(".country-field")
  const countryField = qs("#id_country")
  const hasForeignResidenceField = qs("#id_has_foreign_residence")

  if (countryDiv && hasForeignResidenceField) {
    countryDiv.classList.add("hidden")
    hasForeignResidenceField.addEventListener("change", () => {
      countryDiv.classList.toggle("hidden")
      countryField.value = "CH"
    })
  }
}
