import { theme } from "../../../theme.config.js"
import { useEffect, useMemo, useState } from "preact/hooks"

export const useBreakpoint = (key) => {
  const mql = useMemo(
    () => window.matchMedia(`(min-width: ${theme.screens[key]})`),
    [key],
  )
  const [matches, setMatches] = useState(mql.matches)

  useEffect(() => {
    const handler = () => setMatches(mql.matches)
    mql.addEventListener("change", handler)
    return () => mql.removeEventListener("change", handler)
  }, [mql])

  return matches
}
