import { qs } from "./dom"

export const initImageCropping = async () => {
  const form = qs("#testimonial-create")

  if (form) {
    const { ImageCropping } = await import("./imageCropping.js")
    new ImageCropping(form)
  }
}
