import "./css/reset.css"
import "./css/colors.css"
import "./css/typography.css"
import "./css/utils.css"
import "./css/layout.css"
import "./css/grid.css"
import "./css/icons.css"
import "./css/buttons.css"
import "./css/forms.css"
import "./css/header.css"
import "./css/footer.css"
import "./css/hero.css"
import "./css/details.css"
import "./css/plugins.css"

import "./css/engagement.css"
import "./css/arguments.css"
import "./css/faq.css"
import "./css/articles.css"
import "./css/testimonials.css"
import "./css/donate.css"
import "./css/cards.css"
import "./css/shop.css"
import "./css/cookiecontrol.css"
import "./css/leadmagnet.css"
import "./css/gallery.css"
import "./css/pledge.css"
import "./css/supporters.css"
import "./css/profileImageGenerator.css"
import "./css/map.css"

import "./css/admin.css"
import "./css/variables.css"

import "../node_modules/lightgallery/css/lightgallery.css"
import "../node_modules/lightgallery/css/lg-pager.css"
import "../node_modules/lightgallery/css/lg-autoplay.css"

import { onReady } from "./js/utils/dom"
import {
  initScrolledNav,
  initToggleNav,
  initToggleLangNav,
} from "./js/components/nav"
import { initImageCropping } from "./js/utils/imageCroppingLoader"
import { initTestimonials } from "./js/components/testimonials"
import { initFAQ } from "./js/components/faq"
import { initNewsletterForm } from "./js/components/newsletterForm"
import { initSignatureForm } from "./js/components/signatures"
import { initLeadMagnet } from "./js/components/leadmagnet"
import { initGallery } from "./js/components/gallery"
import { initPledgeProgressBar } from "./js/components/pledge"
import { initPlausibleForLanguageSites } from "./js/utils/plausible"
import { initPayrexxFrame } from "./js/components/payrexx"
import { initSupportingMembersForm } from "./js/components/committees"
import { initProfileImageGenerator } from "./js/components/profileImageGenerator"
import { initMap } from "./js/components/mapLoader"

onReady(initToggleNav)
onReady(initToggleLangNav)
onReady(initScrolledNav)
onReady(initFAQ)
onReady(initTestimonials)
onReady(initNewsletterForm)
onReady(initImageCropping)
onReady(initSignatureForm)
onReady(initLeadMagnet)
onReady(initGallery)
onReady(initPledgeProgressBar)
onReady(initPlausibleForLanguageSites)
onReady(initPayrexxFrame)
onReady(initSupportingMembersForm)
onReady(initProfileImageGenerator)
onReady(initMap)
